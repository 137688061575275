import React from "react";
import { CssBaseline } from "@mui/material";
import { Route, Switch, BrowserRouter } from "react-router-dom";
import Login from "./components/auth/Login";
import SignUp from "./components/auth/SignUp";
import LandingPage from "./components/home/LandingPage";
import Projects from "./components/projects/Projects";
import Forms from "./components/forms/Forms";
import Result from "./components/result/Result";
import PrivateRoute from "./PrivateRoute";

function App() {
  return (
    <div className="App">
      <CssBaseline />
      <main>
        <BrowserRouter>
          <Switch>
            <Route exact path="/">
              <LandingPage />
            </Route>
            <Route exact path="/login">
              <Login />
            </Route>
            <Route exact path="/signup">
              <SignUp />
            </Route>
            <PrivateRoute exact path="/projects" component={Projects} />

            <PrivateRoute exact path="/forms" component={Forms} />

            <PrivateRoute exact path="/result" component={Result} />

            <Route exact path="/404.html" component={Error} />
          </Switch>
        </BrowserRouter>
      </main>
    </div>
  );
}

export default App;
