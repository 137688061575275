import React, { useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import getLPTheme from "../home/getLPTheme";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import Container from "@mui/material/Container";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

import Button from "@mui/material/Button";
import { alpha } from "@mui/material";

function Form3() {
  const defaultTheme = createTheme(getLPTheme("dark"));
  const [data, setdata] = useState(
    JSON.parse(localStorage.getItem("selectedProject"))
  );

  const [existingBuilding, setExistingBuilding] = useState(
    data.project.existingBuilding
  );

  const [ageOfBuildingOlderThan30Year, setAgeOfBuildingOlderThan30Year] =
    useState(data.project.ageOfBuildingOlderThan30Year);

  const [
    existingStructuresBUARoadSetback,
    setExistingStructuresBUARoadSetback,
  ] = useState(data.project.existingStructuresBUARoadSetback);

  const [existingBuildingBUA, setExistingBuildingBUA] = useState(
    data.project.existingBuildingBUA
  );

  const [existingStructuresBUADPRoad, setExistingStructuresBUADPRoad] =
    useState(data.project.existingStructuresBUADPRoad);

  const handleChangeExistingStructuresBUADPRoad = (event) => {
    setExistingStructuresBUADPRoad(event.target.valueAsNumber);
    data.project.existingStructuresBUADPRoad = event.target.valueAsNumber;
    setdata(data);
    localStorage.setItem("selectedProject", JSON.stringify(data));
  };
  const handleChangeExistingBuildingBUA = (event) => {
    setExistingBuildingBUA(event.target.valueAsNumber);
    data.project.existingBuildingBUA = event.target.valueAsNumber;
    setdata(data);
    localStorage.setItem("selectedProject", JSON.stringify(data));
  };

  const handleChangeExistingStructuresBUARoadSetback = (event) => {
    setExistingStructuresBUARoadSetback(event.target.valueAsNumber);
    data.project.existingStructuresBUARoadSetback = event.target.valueAsNumber;
    setdata(data);
    localStorage.setItem("selectedProject", JSON.stringify(data));
  };

  const handleChangeExistingBuilding = (event) => {
    const value = event.target.value.toLowerCase() === "true" ? true : false;
    setExistingBuilding(value);
    data.project.existingBuilding = value;
    setdata(data);
    localStorage.setItem("selectedProject", JSON.stringify(data));
  };

  const handleChangeAgeOfBuildingOlderThan30Year = (event) => {
    const value = event.target.value.toLowerCase() === "true" ? true : false;
    setAgeOfBuildingOlderThan30Year(value);
    data.project.ageOfBuildingOlderThan30Year = value;
    setdata(data);
    localStorage.setItem("selectedProject", JSON.stringify(data));
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box
        id="hero"
        sx={(theme) => ({
          width: "100%",
          backgroundImage: `linear-gradient(#02294F, ${alpha("#090E10", 0.0)})`,
          backgroundSize: "100% 20%",
          backgroundRepeat: "no-repeat",
        })}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <FormControl
            sx={{
              pt: { xs: 4, sm: 4, lg: 3 },
              pb: { xs: 2, sm: 2, lg: 2 },
            }}
          >
            <FormLabel id="existingBuilding">Existing Building:</FormLabel>
            <RadioGroup
              aria-labelledby="existingBuilding-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={existingBuilding}
              onChange={handleChangeExistingBuilding}
            >
              <FormControlLabel value={false} control={<Radio />} label="No" />
              <FormControlLabel value={true} control={<Radio />} label="Yes" />
            </RadioGroup>
          </FormControl>
        </Box>
        {!existingBuilding ? (
          <div></div>
        ) : (
          <Container
            component="main"
            maxWidth="lg"
            disableGutters={true}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "right",
              pt: 3,
            }}
          >
            <CssBaseline />
            <div>Form3</div>
            {/*woking  */}
            {/* Owned Private or Leased */}
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "repeat(3, 1fr)",
              }}
            >
              <FormControl
                sx={{
                  pt: { xs: 4, sm: 4, lg: 3 },
                  pb: { xs: 2, sm: 2, lg: 2 },
                }}
              >
                <FormLabel id="ageOfBuildingOlderThan30Year">
                  Age of the building more than 30 years?
                </FormLabel>
                <RadioGroup
                  aria-labelledby="ageOfBuildingOlderThan30Year-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={ageOfBuildingOlderThan30Year}
                  onChange={handleChangeAgeOfBuildingOlderThan30Year}
                >
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="No"
                  />
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="Yes"
                  />
                </RadioGroup>
              </FormControl>
            </Box>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "repeat(3, 1fr)",
              }}
            >
              <div>
                <InputLabel htmlFor="outlined-adornment-password">
                  Existing Building BUA to be retained:
                </InputLabel>
                <TextField
                  id="outlined-number"
                  type="number"
                  variant="outlined"
                  value={existingBuildingBUA}
                  onChange={handleChangeExistingBuildingBUA}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">m</InputAdornment>
                    ),
                  }}
                />
              </div>
              <div>
                <InputLabel htmlFor="outlined-adornment-password">
                  Existing Structures BUA on DP Road:
                </InputLabel>
                <TextField
                  id="outlined-number"
                  type="number"
                  value={existingStructuresBUADPRoad}
                  onChange={handleChangeExistingStructuresBUADPRoad}
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">m</InputAdornment>
                    ),
                  }}
                />
              </div>
              <div>
                <InputLabel htmlFor="outlined-adornment-password">
                  Existing Structures BUA on Road Setback:
                </InputLabel>
                <TextField
                  id="outlined-number"
                  type="number"
                  variant="outlined"
                  value={existingStructuresBUARoadSetback}
                  onChange={handleChangeExistingStructuresBUARoadSetback}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">m</InputAdornment>
                    ),
                  }}
                />
              </div>
            </Box>
          </Container>
        )}
      </Box>
      {/* <MyDataGrid /> */}
    </ThemeProvider>
  );
}

export default Form3;
