import React, { useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import getLPTheme from "../home/getLPTheme";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import Container from "@mui/material/Container";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

import Button from "@mui/material/Button";
import { alpha } from "@mui/material";
import {
  DataGrid,
  GridActionsCellItem,
  GridRowModes,
  GridToolbarContainer,
  GridRowEditStopReasons,
  GridToolbar,
} from "@mui/x-data-grid";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import { Toolbar } from "@mui/material";

function AddRow(props) {
  const { setRows, setRowModesModel } = props;

  const handleClick = () => {
    const id = Date.now();
    setRows((oldRows) => [
      ...oldRows,
      {
        id,
        plotName: "",
        plotArea: "",
        plotSetBack: "",
        plotReadyReckonerRate: "",
        isNew: true,
      },
    ]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: "name" },
    }));
  };

  return (
    <GridToolbarContainer>
      <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
        Add record
      </Button>
    </GridToolbarContainer>
  );
}

function Form1() {
  const defaultTheme = createTheme(getLPTheme("dark"));
  const [data, setdata] = useState(
    JSON.parse(localStorage.getItem("selectedProject"))
  );

  const [isOwned, setisOwned] = useState(data.project.plotLeased);
  const [isIndustrial, setisIndustrial] = useState(data.project.plotIndustrial);
  const [isIsland, setisIsland] = useState(data.project.island);
  const [existingRoadWidth, setexistingRoadWidth] = useState(
    data.project.existingRoadWidth
  );

  const [dpRoadWidening, setdpRoadWidening] = useState(
    data.project.dpRoadWidening
  );

  const [rows, setRows] = React.useState(data.plotMapList);
  const [rowModesModel, setRowModesModel] = React.useState({});

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const updateLocalStorage = (row) => {
    try {
      const index = data.plotMapList.findIndex((item) => row.id === item.id);
      const listSize = data.plotMapList.length;
      if (index === -1) {
        data.plotMapList = [...data.plotMapList, row];
      } else {
        data.plotMapList[index].plotName = row.plotName;
        data.plotMapList[index].plotArea = row.plotArea;
        data.plotMapList[index].plotReadyReckonerRate =
          row.plotReadyReckonerRate;
        data.plotMapList[index].plotSetBack = row.plotSetBack;
      }
      setdata(data);
      localStorage.setItem("selectedProject", JSON.stringify(data));
    } catch (err) {
      console.log("Error is " + err);
    }
  };
  const handleDeleteClick = (id) => () => {
    setRows(rows.filter((row) => row.id !== id));
    data.plotMapList = data.plotMapList.filter((item) => item.id !== id);
    setdata(data);
    localStorage.setItem("selectedProject", JSON.stringify(data));
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    updateLocalStorage(updatedRow);
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));

    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handleChangedpRoadWidening = (event) => {
    setdpRoadWidening(event.target.valueAsNumber);
    data.project.dpRoadWidening = event.target.valueAsNumber;
    setdata(data);
    localStorage.setItem("selectedProject", JSON.stringify(data));
  };
  const handleChangeExistingRoadWidth = (event) => {
    setexistingRoadWidth(event.target.valueAsNumber);
    data.project.existingRoadWidth = event.target.valueAsNumber;
    setdata(data);
    localStorage.setItem("selectedProject", JSON.stringify(data));
  };
  const handleChangeisIsland = (event) => {
    const value = event.target.value.toLowerCase() === "true" ? true : false;
    setisIsland(value);
    data.project.island = value;
    setdata(data);
    localStorage.setItem("selectedProject", JSON.stringify(data));
  };
  const handleChangeisIndustrial = (event) => {
    const value = event.target.value.toLowerCase() === "true" ? true : false;
    setisIndustrial(value);
    data.project.plotIndustrial = value;
    setdata(data);
    localStorage.setItem("selectedProject", JSON.stringify(data));
  };
  const handleChangeisOwned = (event) => {
    const value = event.target.value.toLowerCase() === "true" ? true : false;
    setisOwned(value);
    data.project.plotLeased = value;
    setdata(data);
    localStorage.setItem("selectedProject", JSON.stringify(data));
  };

  const columns = [
    { field: "plotName", headerName: "CS No.", editable: true },
    {
      field: "plotArea",
      headerName: "Plot Area",
      type: "number",
      editable: true,
    },
    {
      field: "plotSetBack",
      headerName: "Plot SetBack",
      type: "number",
      editable: true,
    },
    {
      field: "plotReadyReckonerRate",
      headerName: "Plot Ready Reckoner Rate",
      type: "number",
      width: 280,
      editable: true,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: "primary.main",
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box
        id="hero"
        sx={(theme) => ({
          width: "100%",
          backgroundImage: `linear-gradient(#02294F, ${alpha("#090E10", 0.0)})`,
          backgroundSize: "100% 20%",
          backgroundRepeat: "no-repeat",
        })}
      >
        <Container
          component="main"
          maxWidth="lg"
          disableGutters={true}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "right",
            pt: 3,
          }}
        >
          <CssBaseline />
          <div>Form1</div>
          <DataGrid
            rows={rows}
            columns={columns}
            editMode="row"
            rowModesModel={rowModesModel}
            onRowModesModelChange={handleRowModesModelChange}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
            slots={{ toolbar: GridToolbar, baseButton: AddRow }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
              },
              baseButton: { setRows, setRowModesModel },
            }}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
          />
          {/*woking  */}
          {/* Owned Private or Leased */}
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(3, 1fr)",
            }}
          >
            <FormControl
              sx={{
                pt: { xs: 4, sm: 4, lg: 3 },
                pb: { xs: 2, sm: 2, lg: 2 },
              }}
            >
              <FormLabel id="owned">Owned:</FormLabel>
              <RadioGroup
                aria-labelledby="owned-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={isOwned}
                onChange={handleChangeisOwned}
              >
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label="Privately Owned"
                />
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label="Leased"
                />
              </RadioGroup>
            </FormControl>
            <FormControl
              sx={{
                pt: { xs: 4, sm: 4, lg: 3 },
                pb: { xs: 2, sm: 2, lg: 2 },
              }}
            >
              <FormLabel id="type">Type:</FormLabel>
              <RadioGroup
                aria-labelledby="type-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={isIndustrial}
                onChange={handleChangeisIndustrial}
              >
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label="Industrial"
                />
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label="Residential"
                />
              </RadioGroup>
            </FormControl>
            <FormControl
              sx={{
                pt: { xs: 4, sm: 4, lg: 3 },
                pb: { xs: 2, sm: 2, lg: 2 },
              }}
            >
              <FormLabel id="location">Location:</FormLabel>
              <RadioGroup
                aria-labelledby="location-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={isIsland}
                onChange={handleChangeisIsland}
              >
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label="Island"
                />
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label="Suburbs"
                />
              </RadioGroup>
            </FormControl>
          </Box>
          <Box>
            <InputLabel htmlFor="outlined-adornment-password">
              Existing Road Width:
            </InputLabel>
            <TextField
              id="outlined-number"
              type="number"
              variant="outlined"
              value={existingRoadWidth}
              onChange={handleChangeExistingRoadWidth}
              InputProps={{
                endAdornment: <InputAdornment position="end">m</InputAdornment>,
              }}
              sx={{
                pt: { xs: 4, sm: 4, lg: 3 },
                pb: { xs: 2, sm: 2, lg: 2 },
              }}
            />

            <InputLabel htmlFor="outlined-adornment-password">
              Proposed DP Road Width: (As per Reg. 16)
            </InputLabel>
            <TextField
              id="outlined-number"
              type="number"
              value={dpRoadWidening}
              onChange={handleChangedpRoadWidening}
              variant="outlined"
              InputProps={{
                endAdornment: <InputAdornment position="end">m</InputAdornment>,
              }}
              sx={{
                pt: { xs: 4, sm: 4, lg: 3 },
                pb: { xs: 2, sm: 2, lg: 2 },
              }}
            />
          </Box>
        </Container>
      </Box>
      {/* <MyDataGrid /> */}
    </ThemeProvider>
  );
}

// //////////////

// const MyDataGrid = () => {
//   const [rows, setRows] = React.useState([
//     { id: 1, firstName: "John", lastName: "Doe", age: 35 },
//     { id: 2, firstName: "Jane", lastName: "Smith", age: 28 },
//     // Add more rows as needed
//   ]);

//   const [filteredRows, setFilteredRows] = React.useState(rows);
//   const [newRow, setNewRow] = React.useState({
//     id: "",
//     firstName: "",
//     lastName: "",
//     age: "",
//   });

//   // Define columns for the DataGrid
//   const columns = [
//     { field: "id", headerName: "ID", width: 70 },
//     { field: "firstName", headerName: "First name", width: 130 },
//     { field: "lastName", headerName: "Last name", width: 130 },
//     { field: "age", headerName: "Age", width: 90 },
//     // Add more columns as needed
//   ];

//   const handleSearch = (event) => {
//     const keyword = event.target.value.toLowerCase();
//     const filteredData = rows.filter(
//       (row) =>
//         row.firstName.toLowerCase().includes(keyword) ||
//         row.lastName.toLowerCase().includes(keyword)
//     );
//     setFilteredRows(filteredData);
//   };

//   const handleAddRow = () => {
//     const id = rows.length + 1;
//     setFilteredRows([...rows, { ...newRow, id }]);
//     setNewRow({ id: "", firstName: "", lastName: "", age: "" });
//   };

//   const handleInputChange = (event) => {
//     const { name, value } = event.target;
//     setNewRow({ ...newRow, [name]: value });
//   };

//   return (
//     <div style={{ height: 400, width: "100%" }}>
//       <input
//         type="text"
//         placeholder="Search..."
//         onChange={handleSearch}
//         style={{ marginBottom: 10 }}
//       />
//       <div style={{ marginBottom: 10 }}>
//         <input
//           type="text"
//           placeholder="ID"
//           name="id"
//           value={newRow.id}
//           onChange={handleInputChange}
//           style={{ marginRight: 10 }}
//         />
//         <input
//           type="text"
//           placeholder="First name"
//           name="firstName"
//           value={newRow.firstName}
//           onChange={handleInputChange}
//           style={{ marginRight: 10 }}
//         />
//         <input
//           type="text"
//           placeholder="Last name"
//           name="lastName"
//           value={newRow.lastName}
//           onChange={handleInputChange}
//           style={{ marginRight: 10 }}
//         />
//         <input
//           type="text"
//           placeholder="Age"
//           name="age"
//           value={newRow.age}
//           onChange={handleInputChange}
//           style={{ marginRight: 10 }}
//         />
//         <button onClick={handleAddRow}>Add Row</button>
//       </div>
//       <DataGrid
//         rows={filteredRows}
//         columns={columns}
//         pageSize={5} // Adjust as per your preference
//         checkboxSelection // Enable checkbox selection if needed
//       />
//     </div>
//   );
// };

// //////////////
export default Form1;
