import React, { Component } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import getLPTheme from "../home/getLPTheme";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import ProjectList from "./ProjectList";
import EmptyProject from "./EmptyProject";
import axios from "axios";
import { apiUrl } from "../../env";

export class Projects extends Component {
  render() {
    // const defaultTheme = createTheme(getLPTheme("dark"));

    // const getCount = async () => {
    //   try {
    //     const userId = localStorage.getItem("userId");
    //     const url = apiUrl + "/project/" + userId;
    //     console.log("url is ", url);
    //     const token = localStorage.getItem("token");

    //     const auth = {
    //       headers: {
    //         "Access-Control-Allow-Origin": "*",
    //         "Access-Control-Allow-Methods": " GET, POST, PUT, DELETE",
    //         "Access-Control-Allow-Headers": "Authorization",
    //         Authorization: "Bearer " + token,
    //       },
    //     };
    //     const response = await axios.get(url, auth);

    //     // axios.get(url, auth).then((result) => {
    //     //   console.log(result.data);
    //     // });
    //     console.log(response.data);
    //     console.log(response.status);
    //     // console.log(response.statusText);
    //     // console.log(response.headers);
    //     // console.log(response.config);
    //     // console.log(response.status === 200);
    //     // if (response.status === 200) {

    //     // }
    //   } catch (error) {
    //     // Handle error
    //     console.error(error);
    //     console.log("Login Failed");
    //   }

    //   return 0;
    // };

    const getCount = () => {
      const projects = JSON.parse(localStorage.getItem("projects"));
      return projects.length;
    };
    const count = getCount();
    if (count > 0) {
      return <ProjectList />;
    } else {
      return <EmptyProject />;
    }
    // return (
    //   <ThemeProvider theme={defaultTheme}>
    //     <Container component="main" maxWidth="xs">
    //       <CssBaseline />
    //       <div>Projects</div>
    //     </Container>
    //   </ThemeProvider>
    // );
  }
}

export default Projects;
