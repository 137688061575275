import React, { useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import getLPTheme from "../home/getLPTheme";
import CssBaseline from "@mui/material/CssBaseline";

import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";

import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { alpha } from "@mui/material";
import Form1 from "./Form1";
import Form2 from "./Form2";
import Form3 from "./Form3";
import Form4 from "./Form4";
import axios from "axios";
import { apiUrl } from "../../env";
import { useHistory } from "react-router-dom";
import NavBar from "../home/NavBar";

export default function Forms() {
  const defaultTheme = createTheme(getLPTheme("dark"));
  const [activeStep, setActiveStep] = useState(0);
  const navigate = useHistory();

  const handleNext = () => {
    console.log("before Updated Step Next ", activeStep);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    if (activeStep + 1 === 1) {
      updateForm1();
    } else if (activeStep + 1 === 2) {
      updateForm2();
    } else if (activeStep + 1 === 3) {
      updateForm3();
    } else if (activeStep + 1 === 4) {
      updateForm4();
    }
    console.log("Updated Step Next ", activeStep);
  };

  const handleBack = () => {
    console.log("before Updated Step back ", activeStep);
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    console.log("Updated Step back ", activeStep);
  };

  const updateForm1 = async () => {
    const data = JSON.parse(localStorage.getItem("selectedProject"));
    const project = data.project;
    const totalPlotArea = data.plotMapList.reduce(function (a, b) {
      return a + b["plotArea"];
    }, 0);
    try {
      const url =
        apiUrl + "/project/updateForm1?projectId=" + project.projectId;
      const listOfPlotMaps = data.plotMapList;
      listOfPlotMaps.forEach((element) => {
        if (Object.keys(element).find((item) => item === "isNew")) {
          element.projectId = project.projectId;
          delete element.id;
          delete element.isNew;
        }
      });

      const postBody = {
        plotMaps: listOfPlotMaps,
        totalPlotArea: totalPlotArea,
        existingRoadWidth: project.existingRoadWidth,
        dpRoadWidening: project.dpRoadWidening,
        island: project.island,
        plotIndustrial: project.plotIndustrial,
        plotLeased: project.plotLeased,
      };

      const token = localStorage.getItem("authToken");
      const auth = {
        headers: {
          accept: "*/*",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": " GET, POST, PUT, DELETE",
          "Access-Control-Allow-Headers": "Authorization",
          Authorization: "Bearer " + token,
        },
      };

      const response = await axios.post(url, postBody, auth);
      // Save resopnse to payload
      // console.log(response.statusText);
      // console.log(response.headers);
      // console.log(response.config);
      //console.log(response.status === 200);
      // if (response.status === 200) {
      // }
    } catch (error) {
      // Handle error
      console.error(error);
      console.log("Failed To Update Form 1 ");
    }
  };

  const updateForm2 = async () => {
    console.log("In Update Form 2 ");
    const data = JSON.parse(localStorage.getItem("selectedProject"));
    const project = data.project;
    try {
      const url =
        apiUrl + "/project/updateForm2?projectId=" + project.projectId;
      const listOfReservationValues = data.reservationValuesList;
      listOfReservationValues.forEach((element) => {
        if (Object.keys(element).find((item) => item === "isNew")) {
          element.projectId = project.projectId;
          delete element.id;
          delete element.isNew;
        }
      });

      const postBody = {
        reservationValuesList: listOfReservationValues,
      };

      const token = localStorage.getItem("authToken");
      const auth = {
        headers: {
          accept: "*/*",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": " GET, POST, PUT, DELETE",
          "Access-Control-Allow-Headers": "Authorization",
          Authorization: "Bearer " + token,
        },
      };

      const response = await axios.post(url, postBody, auth);
      // Save resopnse to payload
      // console.log(response.statusText);
      // console.log(response.headers);
      // console.log(response.config);
      //console.log(response.status === 200);
      // if (response.status === 200) {
      // }
    } catch (error) {
      // Handle error
      console.error(error);
      console.log("Failed to Update reservationValues");
    }
  };

  const updateForm3 = async () => {
    console.log("In Update Form 3 ");
    const data = JSON.parse(localStorage.getItem("selectedProject"));
    const project = data.project;
    try {
      const url =
        apiUrl + "/project/updateForm3?projectId=" + project.projectId;
      const postBody = {
        existingBuilding: project.existingBuilding,
        ageOfBuildingOlderThan30Year: project.ageOfBuildingOlderThan30Year,
        existingBuildingBUA: project.existingBuildingBUA,
        existingStructuresBUADPRoad: project.existingStructuresBUADPRoad,
        existingStructuresBUARoadSetback:
          project.existingStructuresBUARoadSetback,
      };

      const token = localStorage.getItem("authToken");
      const auth = {
        headers: {
          accept: "*/*",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": " GET, POST, PUT, DELETE",
          "Access-Control-Allow-Headers": "Authorization",
          Authorization: "Bearer " + token,
        },
      };

      const response = await axios.post(url, postBody, auth);
      // Save resopnse to payload
      // console.log(response.statusText);
      // console.log(response.headers);
      // console.log(response.config);
      //console.log(response.status === 200);
      // if (response.status === 200) {
      // }
    } catch (error) {
      // Handle error
      console.error(error);
      console.log("Failed to Update From 3 ");
    }
  };

  const updateForm4 = async () => {
    console.log("In Update Form 4 ");
    const data = JSON.parse(localStorage.getItem("selectedProject"));
    const project = data.project;
    try {
      const url =
        apiUrl + "/project/updateForm4?projectId=" + project.projectId;

      const postBody = {
        existingBuildingDemolished: project.existingBuildingDemolished,
        areaOfDemolishing: project.areaOfDemolishing,
      };

      const token = localStorage.getItem("authToken");
      const auth = {
        headers: {
          accept: "*/*",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": " GET, POST, PUT, DELETE",
          "Access-Control-Allow-Headers": "Authorization",
          Authorization: "Bearer " + token,
        },
      };

      const response = await axios.post(url, postBody, auth);
      // Save resopnse to payload
      // console.log(response.statusText);
      // console.log(response.headers);
      // console.log(response.config);
      //console.log(response.status === 200);
      // if (response.status === 200) {
      // }
    } catch (error) {
      // Handle error
      console.error(error);
      console.log("Failed to update Form 4 ");
    }
    await handleFinish();
  };

  const handleFinish = async () => {
    console.log("In Handle Finish ");
    const data = JSON.parse(localStorage.getItem("selectedProject"));
    const project = data.project;

    try {
      const url = apiUrl + "/calculate/" + project.projectId;

      const token = localStorage.getItem("authToken");
      const auth = {
        headers: {
          accept: "*/*",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": " GET, POST, PUT, DELETE",
          "Access-Control-Allow-Headers": "Authorization",
          Authorization: "Bearer " + token,
        },
      };

      const response = await axios.get(url, auth);

      // Save resopnse to payload
      // console.log(response.statusText);
      // console.log(response.headers);
      // console.log(response.config);
      //console.log(response.status === 200);
      if (response.status === 200) {
        localStorage.setItem("result", JSON.stringify(response.data));
        navigate.push("/result");
      }
    } catch (error) {
      // Handle error
      console.error(error);
      console.log("Login Failed");
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <NavBar mode="dark" toggleColorMode="dark" />
      <Box
        id="hero"
        sx={(theme) => ({
          width: "100%",
          backgroundImage: `linear-gradient(#02294F, ${alpha("#02294F", 0.0)})`,
          backgroundSize: "100% 20%",
          backgroundRepeat: "no-repeat",
          pt: 10,
        })}
      >
        <CssBaseline />
        <div>Forms</div>
        <Stepper activeStep={activeStep}>
          <Step>
            <StepLabel>step StepLabel 1</StepLabel>
            Step A
          </Step>
          <Step>
            <StepLabel>step StepLabel 2</StepLabel>
            Step B
          </Step>
          <Step>
            <StepLabel>step StepLabel 3</StepLabel>
            Step C
          </Step>
          <Step>
            <StepLabel>step StepLabel 4</StepLabel>
            Step D
          </Step>
        </Stepper>
        {activeStep === 0 && <Form1 />}
        {activeStep === 1 && <Form2 />}
        {activeStep === 2 && <Form3 />}
        {activeStep === 3 && <Form4 />}
        {/* {activeStep === 1 && <Form2 />}
      {activeStep === 4 && <Form5 />} */}
        {/* <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep}</Typography> */}
        <Box sx={{ display: "flex", flexDirection: "row", pt: 2, pb: 10 }}>
          <Button
            color="inherit"
            disabled={activeStep === 0}
            onClick={() => handleBack()}
            sx={{ mr: 1, pl: 5, pr: 5 }}
          >
            Back
          </Button>
          <Box sx={{ flex: "1 1 auto" }} />

          {/* <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
              Skip
            </Button> */}

          <Button
            size="large"
            sx={{ pl: 5, pr: 5 }}
            onClick={() => handleNext()}
          >
            {activeStep === 3 ? "Finish" : "Next"}
          </Button>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
