import React, { Component, useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import getLPTheme from "../home/getLPTheme";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import DomainAddRoundedIcon from "@mui/icons-material/DomainAddRounded";
import { alpha } from "@mui/material";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";

import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
//import Link from "@mui/material/Link";
import axios from "axios";
import { Link } from "react-router-dom";
//import { Navigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormHelperText from "@mui/material/FormHelperText";
import { apiUrl } from "../../env";
import NavBar from "../home/NavBar";
import { useHistory } from "react-router-dom";

function EmptyProject() {
  const defaultTheme = createTheme(getLPTheme("dark"));
  const [createProject, setCreateProject] = useState(false);
  const navigate = useHistory();

  const handleCreateProject = () => {
    setCreateProject(!createProject);
  };
  const handleClose = () => {
    setCreateProject(false);
  };

  //   Regn. 30(A)
  // Scheme 2 : Regn. 30(A) + 33(20)B
  // Scheme 3 : Regn. 33(7)
  // Scheme 4 : Regn. 33(7) + 33(20)B
  // Scheme 5 : Regn. 33(7) + 33(12)B
  // Scheme 6 : Regn. 33(7) + Floating FSI
  const [state, setState] = React.useState({
    REG_30_A: true,
    REG_30_A_33_20_B: false,
    REG_33_7: false,
    REG_33_7_33_20_B: false,
    REG_33_7_33_12_B: false,
    REG_33_7_FLOATING_FSI: false,
    jason: false,
    antoine: false,
  });

  const handleChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
  };

  const {
    REG_30_A,
    REG_30_A_33_20_B,
    REG_33_7,
    REG_33_7_33_20_B,
    REG_33_7_33_12_B,
    REG_33_7_FLOATING_FSI,
    jason,
    antoine,
  } = state;
  const error =
    [
      REG_30_A,
      REG_30_A_33_20_B,
      REG_33_7,
      REG_33_7_33_20_B,
      REG_33_7_33_12_B,
      REG_33_7_FLOATING_FSI,
      jason,
      antoine,
    ].filter((v) => v).length < 1;

  const createProjectApi = async (formJson) => {
    console.log("createProjectApi ", formJson);
    console.log(typeof formJson);
    try {
      const url = apiUrl + "/project/create";
      const projectName = Object.getOwnPropertyDescriptor(
        formJson,
        "projectName"
      ).value;
      const schemas = Object.keys(formJson).filter(
        (word) => word !== "projectName"
      );
      console.log("url is ", url);

      const postBody = {
        projectName: projectName,
        schemas: schemas,
      };

      console.log(" data ", postBody);

      const token = localStorage.getItem("authToken");

      const auth = {
        headers: {
          accept: "*/*",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": " GET, POST, PUT, DELETE",
          "Access-Control-Allow-Headers": "Authorization",
          Authorization: "Bearer " + token,
        },
      };

      const response = await axios.post(url, postBody, auth);
      // console.log(response.data);
      // console.log(response.status);
      // console.log(response.statusText);
      // console.log(response.headers);
      // console.log(response.config);
      const responseData = response.data;
      if (response.status === 200) {
        // TODO
        // live update on create project -- handle in backend
        // style for create project

        localStorage.setItem("projects", JSON.stringify(responseData));
        navigate.push("/projects");
        // setData(localStorage.getItem("projects"));
      }
    } catch (error) {
      // Handle error
      console.error(error);
    }
  };
  return (
    <ThemeProvider theme={defaultTheme}>
      <NavBar mode="dark" toggleColorMode="dark" />
      <Box
        id="hero"
        sx={(theme) => ({
          width: "100%",
          backgroundImage: `linear-gradient(#02294F, ${alpha("#090E10", 0.0)})`,
          backgroundSize: "100% 20%",
          backgroundRepeat: "no-repeat",
        })}
      >
        <Container
          component="main"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            pt: { xs: 14, sm: 20 },
            pb: { xs: 8, sm: 12 },
          }}
        >
          <CssBaseline />
          <div>EmptyProject</div>
          <h1> Please Create Project To Continue </h1>
          <DomainAddRoundedIcon onClick={handleCreateProject} />

          <Dialog
            open={createProject}
            onClose={handleClose}
            PaperProps={{
              component: "form",
              onSubmit: (event) => {
                event.preventDefault();
                const formData = new FormData(event.currentTarget);
                const formJson = Object.fromEntries(formData.entries());
                createProjectApi(formJson);
                handleClose();
              },
            }}
          >
            <DialogTitle>Create Project</DialogTitle>
            <DialogContent>
              {/* <TextField
                autoFocus
                required
                margin="dense"
                id="name"
                name="email"
                label="Email Address"
                type="email"
                fullWidth
                variant="standard"
              /> */}
              {/* <Container
                component="main"
                maxWidth="xs"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              > */}
              <CssBaseline />
              {/* <Box
                  sx={{
                    marginTop: 2,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                > */}
              <Avatar
                sx={{
                  m: 1,
                  bgcolor: "secondary.main",
                  marginTop: 2,
                  marginLeft: 25,
                  display: "center",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <DomainAddRoundedIcon />
              </Avatar>
              <TextField
                autoComplete="given-name"
                name="projectName"
                required
                fullWidth
                id="projectName"
                label="Project Name"
                autoFocus
              />

              <FormControl
                required
                error={error}
                component="fieldset"
                variant="standard"
              >
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={REG_30_A}
                        onChange={handleChange}
                        name="REG_30_A"
                      />
                    }
                    label="Regn. 30(A)"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={REG_30_A_33_20_B}
                        onChange={handleChange}
                        name="REG_30_A_33_20_B"
                      />
                    }
                    label="Regn. 30(A) + 33(20)B"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={REG_33_7}
                        onChange={handleChange}
                        name="REG_33_7"
                      />
                    }
                    label="Regn. 33(7)"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={REG_33_7_33_20_B}
                        onChange={handleChange}
                        name="REG_33_7_33_20_B"
                      />
                    }
                    label="Regn. 33(7) + 33(20)B"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={REG_33_7_33_12_B}
                        onChange={handleChange}
                        name="REG_33_7_33_12_B"
                      />
                    }
                    label="Regn. 33(7) + 33(12)B"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={REG_33_7_FLOATING_FSI}
                        onChange={handleChange}
                        name="REG_33_7_FLOATING_FSI"
                      />
                    }
                    label="Regn. 33(7) + Floating FSI"
                  />
                </FormGroup>
                <FormHelperText>
                  Please Select At Lease One Scheme
                </FormHelperText>
              </FormControl>

              {/* </Box>
              </Container> */}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button type="submit" disabled={error}>
                Create
              </Button>
            </DialogActions>
          </Dialog>
        </Container>
      </Box>
    </ThemeProvider>
  );
}

export default EmptyProject;
