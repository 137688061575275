import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
//import Link from "@mui/material/Link";
import axios from "axios";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import getLPTheme from "../home/getLPTheme";
import { alpha } from "@mui/material";
import { apiUrl } from "../../env";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" to="https://mui.com/">
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme(getLPTheme("dark"));

export default function SignUp() {
  const [errorMessage, setErrorMessage] = React.useState(false);
  const navigate = useHistory();
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const auth = false;

    for (const pair of data.entries()) {
      console.log(pair[0], pair[1]);
    }

    console.log("All data ", data);
    console.log({
      email: data.get("email"),
      password: data.get("password"),
    });

    const fetchData = async () => {
      console.log("Error is ", errorMessage);
      try {
        const url = apiUrl + "/auth/signup";
        const userName = data.get("firstName") + " " + data.get("lastName");
        console.log("url is ", url);

        const postBody = {
          username: userName,
          email: data.get("email"),
          password: data.get("password"),
        };

        console.log(" data ", postBody);
        const response = await axios.post(url, postBody);
        console.log(response.data);
        console.log(response.status);
        console.log(response.statusText);
        console.log(response.headers);
        console.log(response.config);
        //auth = true;
        if (response.status !== 200) {
          setErrorMessage(!errorMessage);
          console.log("Error is after set ", errorMessage);
        } else {
          callLogin(postBody);
        }
      } catch (error) {
        // Handle error
        setErrorMessage(!errorMessage);
        console.error(error);
      }
    };
    fetchData();
  };

  const callLogin = async (postBody) => {
    try {
      const url = apiUrl + "/auth/login";
      console.log("url is ", url);

      console.log(" data ", postBody);
      const response = await axios.post(url, postBody);
      if (response.status === 200) {
        localStorage.setItem("authToken", response.data.token);
        localStorage.setItem(
          "projects",
          JSON.stringify(response.data.projects)
        );
        navigate.push("/projects");
      }
    } catch (error) {
      // Handle error
      console.error(error);
      console.log("Login Failed");
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box
        id="hero"
        sx={(theme) => ({
          width: "100%",
          backgroundImage: `linear-gradient(#02294F, ${alpha("#090E10", 0.0)})`,
          backgroundSize: "100% 20%",
          backgroundRepeat: "no-repeat",
        })}
      >
        <Container
          component="main"
          maxWidth="xs"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            pt: { xs: 14, sm: 20 },
            pb: { xs: 8, sm: 12 },
          }}
        >
          <CssBaseline />
          <Box
            sx={{
              marginTop: 2,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign up
            </Typography>
            {errorMessage && (
              <Typography
                component="caption"
                variant="caption"
                display="block"
                sx={{ color: "error.main" }}
              >
                User Already Exists
              </Typography>
            )}
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 3 }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    autoComplete="given-name"
                    name="firstName"
                    required
                    id="firstName"
                    label="First Name"
                    autoFocus
                    sx={{ pr: 35 }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    id="lastName"
                    label="Last Name"
                    name="lastName"
                    autoComplete="family-name"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="new-password"
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox value="allowExtraEmails" color="primary" />
                    }
                    label="I want to receive inspiration, marketing promotions and updates via email."
                  />
                </Grid>
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Sign Up
              </Button>
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <Link to="/login" variant="body2">
                    Already have an account? Sign in
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Copyright sx={{ mt: 5 }} />
        </Container>
      </Box>
    </ThemeProvider>
  );
}
