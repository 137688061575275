import React, { useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import getLPTheme from "../home/getLPTheme";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import Container from "@mui/material/Container";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

import Button from "@mui/material/Button";
import { alpha } from "@mui/material";

function Form4() {
  const defaultTheme = createTheme(getLPTheme("dark"));
  const [data, setdata] = useState(
    JSON.parse(localStorage.getItem("selectedProject"))
  );

  const [existingBuildingDemolished, setExistingBuildingDemolished] = useState(
    data.project.existingBuildingDemolished
  );

  const [areaOfDemolishing, setAreaOfDemolishing] = useState(
    data.project.areaOfDemolishing
  );

  const handleChangeAreaOfDemolishing = (event) => {
    setAreaOfDemolishing(event.target.valueAsNumber);
    data.project.areaOfDemolishing = event.target.valueAsNumber;
    setdata(data);
    localStorage.setItem("selectedProject", JSON.stringify(data));
  };

  const handleChangeExistingBuildingDemolished = (event) => {
    const value = event.target.value.toLowerCase() === "true" ? true : false;
    setExistingBuildingDemolished(value);
    data.project.existingBuildingDemolished = value;
    setdata(data);
    localStorage.setItem("selectedProject", JSON.stringify(data));
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box
        id="hero"
        sx={(theme) => ({
          width: "100%",
          backgroundImage: `linear-gradient(#02294F, ${alpha("#090E10", 0.0)})`,
          backgroundSize: "100% 20%",
          backgroundRepeat: "no-repeat",
        })}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <FormControl
            sx={{
              pt: { xs: 4, sm: 4, lg: 3 },
              pb: { xs: 2, sm: 2, lg: 2 },
            }}
          >
            <FormLabel id="existingBuildingDemolished">
              Existing Building BUA that has been already demolished:
            </FormLabel>
            <RadioGroup
              aria-labelledby="existingBuildingDemolished-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={existingBuildingDemolished}
              onChange={handleChangeExistingBuildingDemolished}
            >
              <FormControlLabel value={false} control={<Radio />} label="No" />
              <FormControlLabel value={true} control={<Radio />} label="Yes" />
            </RadioGroup>
          </FormControl>
        </Box>
        {!existingBuildingDemolished ? (
          <div>table 21 </div>
        ) : (
          <Container
            component="main"
            maxWidth="lg"
            disableGutters={true}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "right",
              pt: 3,
            }}
          >
            <CssBaseline />
            <div>Form4</div>
            {/*woking  */}
            {/* Owned Private or Leased */}
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "repeat(1, 1fr)",
              }}
            >
              <InputLabel
                htmlFor="outlined-adornment-password"
                sx={{
                  pt: { xs: 4, sm: 4, lg: 3 },
                }}
              >
                Area of Demolishing:
              </InputLabel>
              <TextField
                id="outlined-number"
                type="number"
                value={areaOfDemolishing}
                onChange={handleChangeAreaOfDemolishing}
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">m</InputAdornment>
                  ),
                }}
                sx={{
                  pt: { xs: 4, sm: 4, lg: 3 },
                  pb: { xs: 2, sm: 2, lg: 2 },
                  maxWidth: 20,
                }}
              />
            </Box>
          </Container>
        )}
      </Box>
      {/* <MyDataGrid /> */}
    </ThemeProvider>
  );
}

export default Form4;
