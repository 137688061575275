import React from "react";

import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import getLPTheme from "../home/getLPTheme";
import { alpha } from "@mui/material";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PropTypes from "prop-types";

import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";

import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import NavBarResults from "../home/NavBarResults";

function Result() {
  const defaultTheme = createTheme(getLPTheme("dark"));

  return (
    <ThemeProvider theme={defaultTheme}>
      <NavBarResults mode="dark" toggleColorMode="dark" />
      <Box
        id="hero"
        sx={(theme) => ({
          width: "100%",
          backgroundImage: `linear-gradient(#02294F, ${alpha("#090E10", 0.0)})`,
          backgroundSize: "100% 20%",
          backgroundRepeat: "no-repeat",
        })}
      >
        <Container
          component="main"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "right",
            pt: { xs: 14, sm: 20 },
            pb: { xs: 8, sm: 12 },
            marginLeft: 25,
          }}
        >
          <CssBaseline />
          <Grid container spacing={2}>
            <VerticalTabs />
          </Grid>
        </Container>
      </Box>
    </ThemeProvider>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}
function FindResultData(data, schemaType) {
  console.log(data);
  console.log(typeof data);
  // check error here -- make indentation to left in result table
  const index = data.findIndex((item) => item.schemaType === schemaType);
  if (index !== -1) return data[index];
  else return {};
}
function VerticalTabs() {
  const [value, setValue] = React.useState(0);
  // check error here
  const data = JSON.parse(localStorage.getItem("result"));
  const REG_30_A = FindResultData(data, "REG_30_A");

  console.log(" REG_30_A ", REG_30_A);

  const REG_30 = FindResultData(data, "REG_30");
  console.log("REG_30 ", REG_30);

  const form30A = Object.keys(REG_30_A).length !== 0 ? true : false;

  const [formOther, setOther] = React.useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        display: "flex",
      }}
    >
      <Tabs
        orientation="vertical"
        variant="fullWidth"
        visibleScrollbar="true"
        value={value}
        onChange={handleChange}
        sx={{ borderRight: 1, borderColor: "divider" }}
      >
        <Tab label="Summary" {...a11yProps(0)} />
        {form30A && <Tab label="REG_30_A" {...a11yProps(1)} />}
        {formOther && <Tab label="Item Three" {...a11yProps(2)} />}
        {formOther && (
          <Tab label="Item Four" sx={{ marginRight: 5 }} {...a11yProps(3)} />
        )}
        {formOther && (
          <Tab label="Item Five" sx={{ marginRight: 5 }} {...a11yProps(4)} />
        )}
        {formOther && (
          <Tab label="Item Six" sx={{ marginRight: 5 }} {...a11yProps(5)} />
        )}
        {formOther && (
          <Tab label="Item Seven" sx={{ marginRight: 5 }} {...a11yProps(6)} />
        )}
      </Tabs>
      <TabPanel value={value} index={0}>
        {DisplaySummary(REG_30_A)}
      </TabPanel>
      <TabPanel value={value} index={1}>
        {Display30A(REG_30_A)}
      </TabPanel>
      <TabPanel value={value} index={2}>
        Item Three
      </TabPanel>
      <TabPanel value={value} index={3}>
        Item Four
      </TabPanel>
      <TabPanel value={value} index={4}>
        Item Five
      </TabPanel>
      <TabPanel value={value} index={5}>
        Item Six
      </TabPanel>
      <TabPanel value={value} index={6}>
        Item Seven
      </TabPanel>
    </Box>
  );
}

function DisplaySummary(data) {
  return (
    <div>
      {/* Summary Table */}
      <TableContainer
        component={Paper}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "left",
          marginLeft: 35,
        }}
      >
        <h3 style={{ textAlign: "center" }}>Summary</h3>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell>Field</TableCell>
              <TableCell>Value</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Gross Plot Area</TableCell>
              <TableCell>{data.grossplotarea || "N/A"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Net Plot Area</TableCell>
              <TableCell>{data.netplotarea || "N/A"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Road Width</TableCell>
              <TableCell>{data.roadwidth || "N/A"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Permissible FSI</TableCell>
              <TableCell>{data.permissiblefsi || "N/A"}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
function Display30A(form30A) {
  return (
    <TableContainer
      component={Paper}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "left",
        marginLeft: 15,
        width: 800,
      }}
    >
      <p style={{ textAlign: "center" }}>
        DEVELOPMENT ON PLOT AS PER Reg 30(A), MUMBAI.
      </p>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell>Sno.</TableCell>
            <TableCell align="left">UNDER REG 30(A)</TableCell>
            <TableCell align="left">Values</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell component="th" scope="row">
              1.
            </TableCell>
            <TableCell align="left">AREA OF PLOT (As per PRC )</TableCell>
            <TableCell align="left">{form30A.totalPlotArea}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              2.
            </TableCell>
            <TableCell align="left">DEDUCTIONS FOR</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              a)
            </TableCell>
            <TableCell align="left">
              SET BACK AREA (As informed by client) - Regn. 16
            </TableCell>
            <TableCell align="left">{form30A.setBackArea}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              b)
            </TableCell>
            <TableCell align="left"> DP ROAD - Regn. 16</TableCell>
            <TableCell align="left">{form30A.dpRoad}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              c)
            </TableCell>
            <TableCell align="left">
              AMENITY (on plot excluding area under road setback/D.P.Road) Regn.
              14
            </TableCell>
            <TableCell align="left">{form30A.amenity}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              d)
            </TableCell>
            <TableCell align="left">
              NET RESERVATION AREA(after amenity deduction)-Regn. 17(1)
            </TableCell>
            <TableCell align="left">{form30A.netReservationArea}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              e)
            </TableCell>
            <TableCell align="left">
              {" "}
              AREA UNDER ENCROACHMENT (as informed by owner) temple + other
              deductions
            </TableCell>
            <TableCell align="left">{form30A.areaUnderENCROACHMENT}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell></TableCell>
            <TableCell component="th" scope="row" align="left">
              TOTAL ( a + b + c + d + e)
            </TableCell>
            <TableCell align="left">{form30A.totalDeductions}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              3
            </TableCell>
            <TableCell align="left">BALANCE AREA OF PLOT ( 1 - 2 )</TableCell>
            <TableCell align="left">{form30A.balanceAreaOfPlot}</TableCell>
          </TableRow>
          {/* <TableRow style={{ backgroundColor: '#E3D7FF', color: 'white' }}>
                <TableCell>4</TableCell>
                <TableCell>CASE: SITE HAVING EXISTING CESS STRUCTURE</TableCell>
                <TableCell></TableCell>
              </TableRow>

              <TableRow>
                <TableCell>a)</TableCell>
                <TableCell>Existing Cess Structure (to be retained) - subdivision</TableCell>
                <TableCell>{form30A.balanceAreaOfPlot}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell>b)</TableCell>
                <TableCell>Land component of Existing Cess structure </TableCell>
                <TableCell>NA</TableCell>
              </TableRow>

              <TableRow style={{ backgroundColor: '#E3D7FF', color: 'white' }}>
                <TableCell>5.</TableCell>
                <TableCell>BALANCE PLOT AREA (Sr.no. 3 - Sr. No. 4b) </TableCell>
                <TableCell>{form30A.balanceAreaOfPlot}</TableCell>
              </TableRow> */}
          <TableRow>
            <TableCell component="th" scope="row">
              6
            </TableCell>
            <TableCell align="left">FSI AS PER ROAD WIDTH (___METER)</TableCell>
            <TableCell align="left">{form30A.roadwidth}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell></TableCell>
            <TableCell component="th" scope="row" align="left">
              F.S.I. PERMISSIBLE (as per Regn. 30(A)){" "}
            </TableCell>
            <TableCell align="left">{form30A.permissiblefsi}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              a)
            </TableCell>
            <TableCell align="left">ZONAL F.S.I. </TableCell>
            <TableCell align="left">{form30A.zonal_FSI_area}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              b)
            </TableCell>
            <TableCell align="left">
              ADDITIONAL F.S.I. (ON PAYMENT OF PREMIUM)
            </TableCell>
            <TableCell align="left">{form30A.additional_FSI_area}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              c)
            </TableCell>
            <TableCell align="left">ADMISSIBLE T.D.R. </TableCell>
            <TableCell align="left">{form30A.admissible_TDR_area}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              7.
            </TableCell>
            <TableCell align="left">
              TOTAL PERMISSIBLE BUA (Sr. No.6a + Sr. No.6b + Sr. No.6c)
            </TableCell>
            <TableCell align="left">{form30A.total_Permissible_BUA}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              8.
            </TableCell>
            <TableCell align="left">
              ADDITIONAL BENEFIT IN LIEU OF HANDING OVER OF ROAD SETBACK{" "}
            </TableCell>
            <TableCell align="left">
              {form30A.additionalBenefitsOfRoadSetBack}
            </TableCell>
          </TableRow>
          {/* <TableRow>
                <TableCell>a)</TableCell>
                <TableCell>In Island City (Sr. No. 2(a)*2.50)</TableCell>
                <TableCell>NA</TableCell>
              </TableRow>

              <TableRow>
                <TableCell>b)</TableCell>
                <TableCell>In Surburbs (Sr. No. 2(a)*2.00)</TableCell>
                <TableCell>NA</TableCell>
              </TableRow> */}
          {/* <TableRow>
                <TableCell colSpan={3}></TableCell>
              </TableRow>

              <TableRow style={{ backgroundColor: '#E3D7FF', color: 'white' }}>
                <TableCell>9</TableCell>
                <TableCell>ADDITIONAL BENEFIT IN LIEU OF HANDING OVER OF DP ROAD </TableCell>
                <TableCell>NA</TableCell>
              </TableRow>

              <TableRow>
                <TableCell>a)</TableCell>
                <TableCell>In Island City (Sr. No. 2(a)*2.50)</TableCell>
                <TableCell>NA</TableCell>
              </TableRow>

              <TableRow>
                <TableCell>b)</TableCell>
                <TableCell>In Surburbs (Sr. No. 2(a)*2.00)</TableCell>
                <TableCell>NA</TableCell>
              </TableRow> */}
          <TableRow>
            <TableCell colSpan={3}></TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              10
            </TableCell>
            <TableCell align="left">
              DEVELOPMENT OF RESERVED LAND AS PER 17(1)
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              a)
            </TableCell>
            <TableCell align="left">
              Permissible BUA as per Regn. 30(A) on balanced reservation area
              after amenity handng over{" "}
            </TableCell>
            <TableCell align="left">
              {form30A.permissibleBUAafteramenityhandingover}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              b)
            </TableCell>
            <TableCell align="left">
              BUA as per Regn. 17(20) - on the land handed over{" "}
            </TableCell>
            <TableCell align="left">{form30A.buaaslandhandedover}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              11.
            </TableCell>
            <TableCell align="left">
              {" "}
              GROSS PERMISSIBLE BUA INCLUDING ROAD SETBACK BENEFIT + REGN. 17(1)
              BENEFIT. (Sr. No.10 + Sr.No.11 + Sr. 12)
            </TableCell>
            <TableCell align="left">{form30A.grosspermissiblebua}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              12.
            </TableCell>
            <TableCell align="left">
              EXISTING REHAB BUA (AS PER DATA RECEIVED){" "}
            </TableCell>
            <TableCell align="left">{form30A.existingrehabbua}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              13.
            </TableCell>
            <TableCell align="left">
              PERMISSIBLE 35% FUNGIBLE BUA ON EXISTING BUA{" "}
            </TableCell>
            <TableCell align="left">
              {form30A.fungiblebuaonexistingbua}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              14.
            </TableCell>
            <TableCell align="left">
              TOTAL REHAB BUA INCLUDING FUNGIBLE{" "}
            </TableCell>
            <TableCell align="left">
              {form30A.totalrehabbuaincludingfungible}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              15.
            </TableCell>
            <TableCell align="left">
              BALANCE PERMISSIBLE BUA FOR SALE{" "}
            </TableCell>
            <TableCell align="left">
              {form30A.balancepermissiblebuaforsale}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              16.
            </TableCell>
            <TableCell align="left">
              35% Fungible BUA for sale by charging premium{" "}
            </TableCell>
            <TableCell align="left">{form30A.fungibleBUAforsale}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              17.
            </TableCell>
            <TableCell align="left">
              GROSS BUA FOR SALE INCLUDING FUNGIBLE{" "}
            </TableCell>
            <TableCell align="left">
              {form30A.grossbuaforsaleincludingfungible}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              18.
            </TableCell>
            <TableCell align="left">
              35% Fungible BUA on reserved plot{" "}
            </TableCell>
            <TableCell align="left">
              {form30A.fungibleBUAonreservedplot}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              19.
            </TableCell>
            <TableCell align="left">
              GROSS BUA INCLUDING FUNGIBLE (REHAB + SALE){" "}
            </TableCell>
            <TableCell align="left">
              {form30A.grossbuaincludingfungiblerehabandSALE}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              20.
            </TableCell>
            <TableCell align="left">RERA CARPET AREA FOR SALE </TableCell>
            <TableCell align="left">{form30A.reracarpetareafORSALE}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default Result;
