import { useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import getLPTheme from "../home/getLPTheme";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import { useHistory } from "react-router-dom";
import { alpha } from "@mui/material";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import EditIcon from "@mui/icons-material/Edit";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DeleteIcon from "@mui/icons-material/Delete";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormHelperText from "@mui/material/FormHelperText";
import { apiUrl } from "../../env";

import DomainAddRoundedIcon from "@mui/icons-material/DomainAddRounded";

import Avatar from "@mui/material/Avatar";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
//import Link from "@mui/material/Link";
import axios from "axios";
import NavBar from "../home/NavBar";

export default function ProjectList() {
  const navigate = useHistory();
  const defaultTheme = createTheme(getLPTheme("dark"));
  const [data, setData] = useState(
    JSON.parse(localStorage.getItem("projects"))
  );

  const [projectName, setProjectName] = useState("");

  const [projectNameID, setProjectNameID] = useState(0);

  const [createProject, setCreateProject] = useState(false);

  const [editProject, setEditProject] = useState(false);

  const handleCreateProject = () => {
    setCreateProject(!createProject);
  };
  const handleClose = () => {
    setCreateProject(false);
  };

  const handleEditProjectOpen = () => {
    setEditProject(!editProject);
  };
  const handleCloseEdit = () => {
    setEditProject(false);
  };

  const [state, setState] = useState({
    REG_30_A: true,
    REG_30_A_33_20_B: false,
    REG_33_7: false,
    REG_33_7_33_20_B: false,
    REG_33_7_33_12_B: false,
    REG_33_7_FLOATING_FSI: false,
    jason: false,
    antoine: false,
  });

  const handleChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
  };

  const {
    REG_30_A,
    REG_30_A_33_20_B,
    REG_33_7,
    REG_33_7_33_20_B,
    REG_33_7_33_12_B,
    REG_33_7_FLOATING_FSI,
    jason,
    antoine,
  } = state;
  const error =
    [
      REG_30_A,
      REG_30_A_33_20_B,
      REG_33_7,
      REG_33_7_33_20_B,
      REG_33_7_33_12_B,
      REG_33_7_FLOATING_FSI,
      jason,
      antoine,
    ].filter((v) => v).length < 1;

  const createProjectApi = async (formJson) => {
    try {
      const url = apiUrl + "/project/create";
      const projectName = Object.getOwnPropertyDescriptor(
        formJson,
        "projectName"
      ).value;
      const schemas = Object.keys(formJson).filter(
        (word) => word !== "projectName"
      );
      console.log("url is ", url);

      const postBody = {
        projectName: projectName,
        schemas: schemas,
      };

      console.log(" data ", postBody);

      const token = localStorage.getItem("authToken");

      const auth = {
        headers: {
          accept: "*/*",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": " GET, POST, PUT, DELETE",
          "Access-Control-Allow-Headers": "Authorization",
          Authorization: "Bearer " + token,
        },
      };

      console.log("Data is ", typeof data);
      const response = await axios.post(url, postBody, auth);
      console.log("response is ", typeof response);
      console.log(response.status);
      const responseData = response.data;

      if (response.status === 200) {
        // TODO
        // live update on create project -- handle in backend
        // style for create project

        setData(responseData);
        localStorage.setItem("projects", JSON.stringify(responseData));
        // setData(localStorage.getItem("projects"));
      }
    } catch (error) {
      // Handle error
      console.error(error);
    }
  };

  const updateProjectApi = async (formJson) => {
    try {
      const url = apiUrl + "/project/update";
      const projectName = Object.getOwnPropertyDescriptor(
        formJson,
        "projectName"
      ).value;
      const schemas = Object.keys(formJson).filter(
        (word) => word !== "projectName"
      );
      console.log("url is ", url);

      const postBody = {
        projectName: projectName,
        schemas: schemas,
        projectId: projectNameID,
      };

      console.log(" data ", postBody);

      const token = localStorage.getItem("authToken");

      const auth = {
        headers: {
          accept: "*/*",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": " GET, POST, PUT, DELETE",
          "Access-Control-Allow-Headers": "Authorization",
          Authorization: "Bearer " + token,
        },
      };

      console.log("Data is ", typeof data);
      const response = await axios.post(url, postBody, auth);
      console.log("response is ", typeof response);
      console.log(response.status);
      const responseData = response.data;

      if (response.status === 200) {
        setData(responseData);
        localStorage.setItem("projects", JSON.stringify(responseData));
      }
    } catch (error) {
      // Handle error
      console.error(error);
    }
  };

  const handleSelectedProject = (item) => {
    localStorage.setItem("selectedProject", JSON.stringify(item));
    navigate.push("/forms");
  };

  const handleEditProject = (item) => {
    setProjectName(item.project.projectName);
    const schemes = item.project.listOfSchemas;
    const updatedState = {
      REG_30_A: schemes.includes("REG_30_A"),
      REG_30_A_33_20_B: schemes.includes("REG_30_A_33_20_B"),
      REG_33_7: schemes.includes("REG_33_7"),
      REG_33_7_33_20_B: schemes.includes("REG_33_7_33_20_B"),
      REG_33_7_33_12_B: schemes.includes("REG_33_7_33_12_B"),
      REG_33_7_FLOATING_FSI: schemes.includes("REG_33_7_FLOATING_FSI"),
      jason: false,
      antoine: false,
    };
    setState(updatedState);
    setProjectNameID(item.project.projectId);
    handleEditProjectOpen();
  };

  const handleDeleteProject = async (item) => {
    try {
      const url = apiUrl + "/project/delete/" + item.project.projectId;

      console.log("url is ", url);

      const token = localStorage.getItem("authToken");

      const auth = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };

      const response = await axios.get(url, auth);
      const responseData = response.data;

      if (response.status === 200) {
        // TODO
        // live update on create project -- handle in backend
        // style for create project
        setData(responseData);
        localStorage.setItem("projects", JSON.stringify(responseData));
        // setData(localStorage.getItem("projects"));
      }
    } catch (error) {
      // Handle error
      console.error(error);
    }
  };

  //map data and render list ;
  return (
    <ThemeProvider theme={defaultTheme}>
      <NavBar mode="dark" toggleColorMode="dark" sx={{ pt: 100 }} />
      <Box
        id="hero"
        sx={(theme) => ({
          width: "100%",
          backgroundImage: `linear-gradient(#02294F, ${alpha("#090E10", 0.0)})`,
          backgroundSize: "100% 20%",
          backgroundRepeat: "no-repeat",
          pt: 10,
        })}
      >
        <Container component="main">
          <CssBaseline />
          Project List
          <Grid container spacing={2}>
            {data.map((item, i) => (
              <Grid item key={i}>
                <Card sx={{ minWidth: 150, maxWidth: 275 }}>
                  {console.log("Item is ", item)}

                  {/* {item.projectName} */}
                  <CardContent>
                    <Typography
                      sx={{ fontSize: 14 }}
                      color="text.secondary"
                      gutterBottom
                    >
                      Project Name
                    </Typography>
                    <Typography variant="h5" component="div">
                      {item.project.projectName}
                    </Typography>
                    <Typography sx={{ mb: 1.5 }} color="text.secondary">
                      Regn.
                    </Typography>
                    {item.project.listOfSchemas.map((item, i) => (
                      <Typography variant={"body2"} key={item}>
                        {item} <br />
                      </Typography>
                    ))}
                  </CardContent>
                  <CardActions>
                    <Button
                      size="small"
                      onClick={() => handleSelectedProject(item)}
                    >
                      Explore Project
                    </Button>
                    <Button
                      size="small"
                      onClick={() => handleEditProject(item)}
                    >
                      <EditIcon />
                    </Button>
                    <Button
                      size="small"
                      onClick={() => handleDeleteProject(item)}
                    >
                      <DeleteIcon />
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
            <DomainAddRoundedIcon
              onClick={handleCreateProject}
              sx={{
                m: 6,
                mt: 12,
              }}
            />
          </Grid>
          <Dialog
            open={createProject}
            onClose={handleClose}
            PaperProps={{
              component: "form",
              onSubmit: (event) => {
                event.preventDefault();
                const formData = new FormData(event.currentTarget);
                const formJson = Object.fromEntries(formData.entries());
                createProjectApi(formJson);
                handleClose();
              },
            }}
          >
            <DialogTitle>Create Project</DialogTitle>
            <DialogContent>
              <Avatar
                sx={{
                  m: 1,
                  bgcolor: "secondary.main",
                  marginTop: 2,
                  marginLeft: 25,
                  display: "center",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <DomainAddRoundedIcon />
              </Avatar>
              <TextField
                autoComplete="given-name"
                name="projectName"
                required
                fullWidth
                id="projectName"
                label="Project Name"
                autoFocus
              />

              <FormControl
                required
                error={error}
                component="fieldset"
                variant="standard"
              >
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={REG_30_A}
                        onChange={handleChange}
                        name="REG_30_A"
                      />
                    }
                    label="Regn. 30(A)"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={REG_30_A_33_20_B}
                        onChange={handleChange}
                        name="REG_30_A_33_20_B"
                      />
                    }
                    label="Regn. 30(A) + 33(20)B"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={REG_33_7}
                        onChange={handleChange}
                        name="REG_33_7"
                      />
                    }
                    label="Regn. 33(7)"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={REG_33_7_33_20_B}
                        onChange={handleChange}
                        name="REG_33_7_33_20_B"
                      />
                    }
                    label="Regn. 33(7) + 33(20)B"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={REG_33_7_33_12_B}
                        onChange={handleChange}
                        name="REG_33_7_33_12_B"
                      />
                    }
                    label="Regn. 33(7) + 33(12)B"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={REG_33_7_FLOATING_FSI}
                        onChange={handleChange}
                        name="REG_33_7_FLOATING_FSI"
                      />
                    }
                    label="Regn. 33(7) + Floating FSI"
                  />
                </FormGroup>
                <FormHelperText>
                  Please Select At Lease One Scheme
                </FormHelperText>
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button type="submit" disabled={error}>
                Create
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={editProject}
            onClose={handleCloseEdit}
            PaperProps={{
              component: "form",
              onSubmit: (event) => {
                event.preventDefault();
                const formData = new FormData(event.currentTarget);
                const formJson = Object.fromEntries(formData.entries());
                updateProjectApi(formJson);
                handleCloseEdit();
              },
            }}
          >
            <DialogTitle>Update Project</DialogTitle>
            <DialogContent>
              <Avatar
                sx={{
                  m: 1,
                  bgcolor: "secondary.main",
                  marginTop: 2,
                  marginLeft: 25,
                  display: "center",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <DomainAddRoundedIcon />
              </Avatar>
              <TextField
                autoComplete="given-name"
                name="projectName"
                required
                fullWidth
                id="projectName"
                label="Project Name"
                autoFocus
                defaultValue={projectName}
              />

              <FormControl
                required
                error={error}
                component="fieldset"
                variant="standard"
              >
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={REG_30_A}
                        onChange={handleChange}
                        name="REG_30_A"
                      />
                    }
                    label="Regn. 30(A)"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={REG_30_A_33_20_B}
                        onChange={handleChange}
                        name="REG_30_A_33_20_B"
                      />
                    }
                    label="Regn. 30(A) + 33(20)B"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={REG_33_7}
                        onChange={handleChange}
                        name="REG_33_7"
                      />
                    }
                    label="Regn. 33(7)"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={REG_33_7_33_20_B}
                        onChange={handleChange}
                        name="REG_33_7_33_20_B"
                      />
                    }
                    label="Regn. 33(7) + 33(20)B"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={REG_33_7_33_12_B}
                        onChange={handleChange}
                        name="REG_33_7_33_12_B"
                      />
                    }
                    label="Regn. 33(7) + 33(12)B"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={REG_33_7_FLOATING_FSI}
                        onChange={handleChange}
                        name="REG_33_7_FLOATING_FSI"
                      />
                    }
                    label="Regn. 33(7) + Floating FSI"
                  />
                </FormGroup>
                <FormHelperText>
                  Please Select At Lease One Scheme
                </FormHelperText>
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseEdit}>Cancel</Button>
              <Button type="submit" disabled={error}>
                Update
              </Button>
            </DialogActions>
          </Dialog>
        </Container>
      </Box>
    </ThemeProvider>
  );
}
