import React, { useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import getLPTheme from "../home/getLPTheme";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import { alpha } from "@mui/material";
import {
  DataGrid,
  GridActionsCellItem,
  GridRowModes,
  GridToolbarContainer,
  GridRowEditStopReasons,
  GridToolbar,
} from "@mui/x-data-grid";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";

function AddRow(props) {
  const { setRows, setRowModesModel } = props;

  const handleClick = () => {
    const id = Date.now();
    setRows((oldRows) => [
      ...oldRows,
      {
        id,
        reservationType: "",
        buildableReservation: "",
        nonBuildableReservation: "",
        existingStructureOnReservation: "",
        designatedAreaForReservation: "",
        isNew: true,
      },
    ]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: "reservationType" },
    }));
  };

  return (
    <GridToolbarContainer>
      <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
        Add record
      </Button>
    </GridToolbarContainer>
  );
}

function Form2() {
  const defaultTheme = createTheme(getLPTheme("dark"));
  const [data, setdata] = useState(
    JSON.parse(localStorage.getItem("selectedProject"))
  );

  const [rows, setRows] = React.useState(data.reservationValuesList);
  const [rowModesModel, setRowModesModel] = React.useState({});

  const options1 = [
    "ROS 1.2",
    "ROS 2.3",
    "ROS 2.4",
    "ROS 2.7",
    "ROS 2.8",
    "RPU 2.1",
    "RSA 5.1",
    "RSA 7.1",
    "RSA 8.1",
    "RMS 3.2",
    "RMS 4.1",
    "RMS 4.3",
    "RMS 5.1",
    "RMS 5.2",
    "RMS 5.3",
    "RMS 6.1",
    "RT 1.2",
    "RT 2.1",
    "RT 2.2",
    "RAM",
    "RE 1.1",
    "RE 1.2",
    "RE 1.3",
    "RE 2.1",
    "RE 3.1",
    "RE 4.1",
    "RE 4.2",
    "RE 4.3",
    "RE 4.4",
    "RH 1.1",
    "RH 1.2",
    "RH 1.3",
    "RH 3.1",
    "RH 3.4",
    "ROS 1.4",
    "ROS 1.5",
    "ROS 2.1",
    "ROS 2.5",
    "RR 1.1",
    "RR 1.2",
    "RR 1.3",
    "RR 1.5",
    "RR 1.6",
    "RR 1.7",
    "RR 2.1",
    "RR 2.2",
    "RPU 1.1",
    "RPU 3.1",
    "RPU 3.2",
    "RPU 3.3",
    "RPU 3.4",
    "RPU 3.5",
    "RPU 4.1",
    "RPU 5.2",
    "RPU 6.1",
    "RO 1.3",
    "RO 2.1",
    "RO 3.1",
    "RSA 1.1",
    "RSA 1.2",
    "RSA 2.1",
    "RSA 2.7",
    "RSA 2.9",
    "RSA 3.3",
    "RSA 3.5",
    "RSA 3.6",
    "RSA 3.7",
    "RSA 4.8",
    "RSA 4.9",
    "RSA 5.2",
    "RSA 6.1",
    "RSA 6.2",
    "RSA 6.3",
    "RMS 1.2",
    "RMS 1.3",
    "RMS 2.1",
    "RMS 3.1",
    "RMS 3.3",
    "RMS 5.5",
    "RP 1.1",
    "RP 2.1",
    "RT 1.1",
    "RT 1.4",
    "RT 1.6",
    "RT 3.1",
    "RE 3.1",
    "RE 2.1",
  ];
  const options = [
    "ROS_1_2",
    "ROS_2_3",
    "ROS_2_4",
    "ROS_2_7",
    "ROS_2_8",
    "RPU_2_1",
    "RSA_5_1",
    "RSA_7_1",
    "RSA_8_1",
    "RMS_3_2",
    "RMS_4_1",
    "RMS_4_3",
    "RMS_5_1",
    "RMS_5_2",
    "RMS_5_3",
    "RMS_6_1",
    "RT_1_2",
    "RT_2_1",
    "RT_2_2",
    "RAM",
    "RE_1_1",
    "RE_1_2",
    "RE_1_3",
    "RE_2_1",
    "RE_3_1",
    "RE_4_1",
    "RE_4_2",
    "RE_4_3",
    "RE_4_4",
    "RH_1_1",
    "RH_1_2",
    "RH_1_3",
    "RH_3_1",
    "RH_3_4",
    "ROS_1_4",
    "ROS_1_5",
    "ROS_2_1",
    "ROS_2_5",
    "RR_1_1",
    "RR_1_2",
    "RR_1_3",
    "RR_1_5",
    "RR_1_6",
    "RR_1_7",
    "RR_2_1",
    "RR_2_2",
    "RPU_1_1",
    "RPU_3_1",
    "RPU_3_2",
    "RPU_3_3",
    "RPU_3_4",
    "RPU_3_5",
    "RPU_4_1",
    "RPU_5_2",
    "RPU_6_1",
    "RO_1_3",
    "RO_2_1",
    "RO_3_1",
    "RSA_1_1",
    "RSA_1_2",
    "RSA_2_1",
    "RSA_2_7",
    "RSA_2_9",
    "RSA_3_3",
    "RSA_3_5",
    "RSA_3_6",
    "RSA_3_7",
    "RSA_4_8",
    "RSA_4_9",
    "RSA_5_2",
    "RSA_6_1",
    "RSA_6_2",
    "RSA_6_3",
    "RMS_1_2",
    "RMS_1_3",
    "RMS_2_1",
    "RMS_3_1",
    "RMS_3_3",
    "RMS_5_5",
    "RP_1_1",
    "RP_2_1",
    "RT_1_1",
    "RT_1_4",
    "RT_1_6",
    "RT_3_1",
    "RE_3_1",
    "RE_2_1",
  ];
  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const updateLocalStorage = (row) => {
    try {
      const index = data.reservationValuesList.findIndex(
        (item) => row.id === item.id
      );
      if (index === -1) {
        data.reservationValuesList = [...data.reservationValuesList, row];
      } else {
        data.reservationValuesList[index].reservationType = row.reservationType;
        data.reservationValuesList[index].buildableReservation =
          row.buildableReservation;
        data.reservationValuesList[index].nonBuildableReservation =
          row.nonBuildableReservation;
        data.reservationValuesList[index].existingStructureOnReservation =
          row.existingStructureOnReservation;
        data.reservationValuesList[index].designatedAreaForReservation =
          row.designatedAreaForReservation;
      }
      setdata(data);
      localStorage.setItem("selectedProject", JSON.stringify(data));
    } catch (err) {
      console.log("Error is " + err);
    }
  };
  const handleDeleteClick = (id) => () => {
    setRows(rows.filter((row) => row.id !== id));
    data.reservationValuesList = data.reservationValuesList.filter(
      (item) => item.id !== id
    );
    setdata(data);
    localStorage.setItem("selectedProject", JSON.stringify(data));
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    updateLocalStorage(updatedRow);
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));

    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const columns = [
    {
      field: "reservationType",
      headerName: "Reservation Type",
      editable: true,
      width: 200,
      type: "singleSelect",
      valueOptions: options,
    },
    {
      field: "buildableReservation",
      headerName: "Buildable Reservation",
      type: "number",
      editable: true,
      width: 200,
    },
    {
      field: "nonBuildableReservation",
      headerName: "Non-Buildable Reservation",
      type: "number",
      editable: true,
      width: 200,
    },
    {
      field: "existingStructureOnReservation",
      headerName: "Existing Structure Area",
      type: "number",
      editable: true,
      width: 200,
    },
    {
      field: "designatedAreaForReservation",
      headerName: "Designated Area for Reservation",
      type: "number",
      editable: true,
      width: 250,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: "primary.main",
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box
        id="hero"
        sx={(theme) => ({
          width: "100%",
          backgroundImage: `linear-gradient(#02294F, ${alpha("#090E10", 0.0)})`,
          backgroundSize: "100% 20%",
          backgroundRepeat: "no-repeat",
        })}
      >
        <Container
          component="main"
          maxWidth="lg"
          disableGutters={true}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "right",
            pt: 3,
          }}
        >
          <CssBaseline />
          <div>Form2</div>
          <DataGrid
            rows={rows}
            columns={columns}
            editMode="row"
            rowModesModel={rowModesModel}
            onRowModesModelChange={handleRowModesModelChange}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
            slots={{ toolbar: GridToolbar, baseButton: AddRow }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
              },
              baseButton: { setRows, setRowModesModel },
            }}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
          />
        </Container>
      </Box>
    </ThemeProvider>
  );
}
export default Form2;
